import * as S from "./styles";

import {
  GET_COUPONS_COUNT,
  GET_SOURCING_REQUESTS,
  GET_USER_EMAIL,
  STAFF_MEMBERS,
} from "./queries";
import { useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import ErrorPage from "../../components/ErrorPage/ErrorPage";
import Filters from "./components/Filters";
import { GET_USER_DATA } from "../MasterDashboard/queries";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import ReactModal from "react-modal";
import Table from "../../components/Table";
import { UPDATE_RECOMMENDED } from "./mutations";
import UpdateContentStatus from "./UpdateContent-Status";
import { convertToUrl } from "../../utils/utils";
import moment from "moment";
import { toast } from "react-toastify";
import { NextDateModal } from "./NextDateModal";
import { NotificationModal } from "./NotificationModal";

const SourceWithZaamo = () => {
  const [sourcingData, setSourcingData] = useState([]);
  const [page, setPage] = useState(1);
  const [nextModal, setNextModal] = useState(false);
  const [nextData, setNextData] = useState(null);

  const [modalShow, setModalShow] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [statusContentDict, setStatusContentDict] = useState(undefined);
  //filter states
  const [nextDate, setNextDate] = useState({});
  const [time, setTime] = useState({});
  const [brand, setBrand] = useState("");
  const [stores, setStores] = useState("");
  const [sortBy, setSortBy] = useState({
    label: "Updated At",
    value: "UPDATED_AT",
  });
  const [status, setStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [direction, setDirection] = useState({ label: "Desc", value: "DESC" });
  const [collab, setCollab] = useState("");
  const [search, setSearch] = useState("");
  const [storeStatus, setStoreStatus] = useState([]);
  const [barter, setBarter] = useState(undefined);
  const [recommended, setRecommended] = useState(undefined);

  const initialFetch = useRef(false);

  const { data, loading, error } = useQuery(GET_USER_EMAIL);
  const {
    data: sourcingRequests,
    loading: loading2,
    error: error2,
    fetchMore,
    refetch,
  } = useQuery(GET_SOURCING_REQUESTS, {
    notifyOnNetworkStatusChange: true,
    skip: !data,
    variables: {
      email: search?.value
        ? search?.value
        : data?.me?.isSuperuser
        ? undefined
        : data?.me?.email,
      brands: brand ? [brand.value] : [],
      stores: stores?.length ? stores.map((e) => e.value) : [],
      sortBy: sortBy?.value,
      direction: direction?.value,
      status: status?.map((e) => e.value),
      time: Object.keys(time).length > 0 ? time : {},
      nextDate: Object.keys(nextDate).length > 0 ? nextDate : {},
      collab: collab?.value,
      storeStatus: storeStatus?.length ? storeStatus.map((e) => e.value) : [],
      barter: barter?.value,
      orderStatus: orderStatus.map((e) => e.value),
      recommended: recommended?.value,
    },
  });
  const [
    getCouponCount,
    { data: couponCount, loading: CCloading, error: CCerror },
  ] = useLazyQuery(GET_COUPONS_COUNT, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const variables = {};
    if (brand) variables.brandIds = brand?.value;
    if (time.gte) variables.startDate = time?.gte;
    if (time.lte) variables.endDate = time?.lte;

    getCouponCount({ variables });
  }, [brand, time]);
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER_DATA, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  const {
    data: staff,
    loading: staffLoading,
    error: staffError,
  } = useQuery(STAFF_MEMBERS);

  const [updateRecommend, { error: updErr }] = useMutation(UPDATE_RECOMMENDED);

  const insertNewLine = (list) => {
    //empty
    if (!list) return "--";
    for (let i = 2; i < list.length; i += 3) {
      list.splice(i, 0, "\n");
    }
    let newStr = "";
    for (let i = 0; i < list.length; i++) {
      if (list[i] !== "\n" && i !== list.length - 1) newStr += list[i] + ",";
      else newStr += list[i];
    }
    return newStr;
  };

  //call query whenever filter are updated and on first render
  useEffect(() => {
    if (data) {
      setPage(1);
    }
    // alert("nextdate change");
    refetch();
    initialFetch.current = false;
  }, [
    data,
    brand,
    stores,
    sortBy,
    direction,
    status,
    time,
    nextDate,
    collab,
    search,
    storeStatus,
    orderStatus,
    barter,
    recommended,
  ]);

  useEffect(() => {
    if (sourcingRequests) {
      let temp = {};
      sourcingRequests?.sourcingRequest?.edges?.map((e) => {
        temp[e.node.id] = {
          status: e.node?.status,
          content: e.node?.content,
        };
      });
      setStatusContentDict(temp);
    }
  }, [sourcingRequests]);

  useEffect(() => {
    if (statusContentDict && sourcingRequests) {
      if (sourcingRequests?.sourcingRequest) {
        const data = sourcingRequests?.sourcingRequest?.edges?.map(
          ({ node }, index) => ({
            sourcingRequestId: atob(node?.id).replace("SourcingRequest:", ""),
            storeName: {
              link: {
                url: node?.store?.storeUrl,
                name: node?.store?.storeName ?? "--",
              },
            },
            stopSourceWithZaamo: node?.stopSourceWithZaamo ? "True" : "False",
            content: {
              function: () => {
                setModalShow(true);
                setModalData({
                  content: statusContentDict[node.id].content,
                  status: statusContentDict[node.id].status,
                  id: node.id,
                });
              },
              hyperlink: true,
              name: convertToUrl(statusContentDict[node.id].content ?? "--"),
            },
            notification: {
              value: node?.notification,
              function: () => {
                setModalData({ id: node?.id });
                node?.notification
                  ? toast.warn("Whatsapp Notification already active")
                  : setNotificationModal(true);
              },
            },
            userLastUpdated: node?.userLastUpdatedEmail ?? "--",
            image: {
              img: node?.product?.thumbnail?.url,
            },
            productName: node?.product?.name ?? "--",
            brandName: node?.brand?.brandName ?? "--",
            variantName: node?.variant?.name ?? "--",
            orderStatus: node?.orderStatus ?? "--",
            deliveryDate: `${node?.deliveryDate ?? "--"} : ${
              node?.daysSinceProductsDelivered ?? "--"
            }`,
            support: {
              link: {
                url: `/prod/dashboard/master?order_id=${node?.orderId}`,
                name: node?.orderId ? "View Orders" : "No Orders",
              },
            },
            instagram: {
              link: {
                url: node?.instagramLink ? node?.instagramLink : "--",
                name: node?.instagramLink ? "View" : "--",
              },
            },
            storeStatus: JSON.parse(node?.store?.metadata)
              ?.sheeko_influencer_info
              ? {
                  link: {
                    url: JSON.parse(node?.store?.metadata)
                      ?.sheeko_influencer_info,
                    name: node?.storeStatus ?? "--",
                  },
                }
              : node?.storeStatus ?? "--",
            variantMRP: node?.variant?.costPrice?.amount ?? "--",
            variantMSP: node?.variant?.price?.amount ?? "--",
            currentInv: node?.variant?.quantityAvailable ?? "--",
            brandCollab: node?.brandCollab,
            brandBarterGuidelines: {
              hyperlink: true,
              name: convertToUrl(node?.brand?.brandBarterGuidelines ?? "--"),
            },
            influencerManagers: insertNewLine(
              node?.influencerManagers.split(",")
            ),
            brandManagers: insertNewLine(node?.brandManagers.split(",")),
            updatedAt: moment(node?.updatedAt).format("DD-MMM-YY HH:MM"),
            createdAt: moment(node?.createdAt).format("DD-MMM-YY HH:MM"),
            recommended: {
              checkbox: true,
              function: async (bool, data) => {
                const res = await updateRecommend({
                  variables: { id: node?.id, bool },
                });
                if (res?.data?.sourcingRequestUpdate)
                  return toast.success("Recommended Updated!");
              },
              bool: node?.recommended ?? false,
            },
            requestReceived: node?.requestReceived ?? "--",
            brandCouponCreated: node?.brandCouponCreated ?? "--",
            brandSharedDeliverables: node?.brandSharedDeliverables ?? "--",
            status: {
              function: () => {
                setModalShow(true);
                setModalData({
                  content: statusContentDict[node.id].content,
                  status: statusContentDict[node.id].status,
                  id: node.id,
                });
              },
              name: statusContentDict[node.id].status,
            },
            nextTask: {
              function: () => {
                setNextModal(true);
                setNextData({
                  id: node?.id,
                  nextDate: String(node?.nextDate).split("T")[0],
                  nextTask: node?.nextTask,
                });
              },
              name: node?.nextTask || "--",
            },
            nextDate: {
              function: () => {
                setNextModal(true);
                setNextData({
                  id: node?.id,
                  nextDate: String(node?.nextDate).split("T")[0],
                  nextTask: node?.nextTask,
                });
              },
              name: node?.nextDate
                ? String(node?.nextDate).split("T")[0]
                : "--",
            },
            influencerNotes:
              JSON.parse(node?.store?.metadata)?.influencer_notes ?? "",
          })
        );
        setSourcingData(data);
      }
    }
  }, [statusContentDict, page]);

  //called when filters are applied
  const onChange = (value, type) => {
    if (type === "BRAND") setBrand(value);
    else if (type === "STORES") setStores(value);
    else if (type === "DIR") setDirection(value);
    else if (type === "SORT") setSortBy(value);
    else if (type === "STATUS") setStatus(value);
    else if (type === "ORDERSTATUS") setOrderStatus(value);
    else if (type === "TIME") setTime(value);
    else if (type === "NEXTDATE") setNextDate(value);
    else if (type === "COLLAB") setCollab(value);
    else if (type === "SEARCH") setSearch(value);
    else if (type === "STATE") setStoreStatus(value);
    else if (type === "BARTER") setBarter(value);
    else if (type === "RECOMMENDED") setRecommended(value);
    console.log(type, value);
  };

  //resetting the filters
  const clearAll = () => {
    setBrand("");
    setStores([]);
    setTime({});
    setNextDate({});
    setDirection({ label: "Desc", value: "DESC" });
    setSortBy({
      label: "Updated At",
      value: "UPDATED_AT",
    });
    setStatus([]);
    setOrderStatus([]);
    setCollab("");
    setStoreStatus([]);
  };

  useEffect(() => {
    if (loading || loading2 || userLoading || staffLoading) {
    } else {
      initialFetch.current = true;
    }
  }, [loading, loading2, userLoading, staffLoading]);

  if ((loading || loading2 || userLoading || staffLoading) && !initialFetch)
    return <PacmanLoader />;
  if (error || error2 || userError || staffError || updErr)
    return <ErrorPage />;

  return (
    <>
      <Layout
        pagination={{
          hasNext:
            sourcingRequests?.sourcingRequest?.pageInfo?.hasNextPage ||
            page * 100 < sourcingData.length,
          current: page,
          onNextClick: () => {
            setPage(page + 1);
            fetchMore({
              variables: {
                endCursor:
                  sourcingRequests?.sourcingRequest?.pageInfo?.endCursor,
              },
            });
          },
          onPrevClick: () => setPage(page - 1),
        }}
        title="SOURCING WITH ZAAMO"
        desktop
      >
        <Filters
          data={userData?.userByMobile}
          clearAll={clearAll}
          onChange={onChange}
          staff={staff?.staffUsers?.edges.map(({ node }) => ({
            label: node.email,
            value: node.email,
          }))}
          defaultValue={{
            brand: brand,
            stores: stores,
            status: status,
            sortBy: sortBy,
            direction: direction,
            nextDate: nextDate,
            time: time,
            collab: collab,
            search: search,
            storeStatus: storeStatus,
          }}
        />
        <S.Grid>
          <S.Card>
            <span>Total Count</span>
            <p>{sourcingRequests?.sourcingRequest.totalCount}</p>
          </S.Card>
          <S.Card>
            <span>Coupon Count</span>
            <p>
              {couponCount?.sourceWithZaamoKpi?.countOfCouponsCreatedByBrand ||
                0}
            </p>
          </S.Card>
        </S.Grid>
        <Table data={sourcingData} displayPageSize={100} currentPage={page} />
        <ReactModal
          isOpen={modalShow}
          onRequestClose={() => setModalShow(false)}
          style={S.Modal}
        >
          <UpdateContentStatus
            modalData={{
              ...modalData,
              setStatusContentDict,
              statusContentDict,
              setModalShow,
            }}
          />
        </ReactModal>
        <ReactModal
          isOpen={nextModal}
          onRequestClose={() => setNextModal(false)}
          style={S.Modal}
        >
          <NextDateModal
            id={nextData?.id}
            date={nextData?.nextDate}
            task={nextData?.nextTask}
            setModal={setNextModal}
          />
        </ReactModal>
        <ReactModal
          isOpen={notificationModal}
          onRequestClose={() => setNotificationModal(false)}
          style={S.Modal}
        >
          <NotificationModal
            id={modalData.id}
            setModalClose={() => setNotificationModal(false)}
            refetch={refetch}
          />
        </ReactModal>
      </Layout>
    </>
  );
};

export default SourceWithZaamo;
