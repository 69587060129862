import { gql } from "@apollo/client";

export const GET_USER_EMAIL = gql`
  query {
    me {
      email
      isSuperuser
    }
  }
`;

export const STAFF_MEMBERS = gql`
  query {
    staffUsers(first: 100) {
      edges {
        node {
          email
        }
      }
    }
  }
`;

export const GET_COUPONS_COUNT = gql`
  query sourceWithZaamoKpi($startDate: Date, $endDate: Date, $brandIds: [ID]) {
    sourceWithZaamoKpi(
      startDate: $startDate
      endDate: $endDate
      brandIds: $brandIds
    ) {
      countOfCouponsCreatedByBrand
    }
  }
`;

export const GET_SOURCING_REQUESTS = gql`
  query GetSourcingRequests(
    $email: String
    $endCursor: String
    $stores: [ID]
    $brands: [ID]
    $direction: OrderDirection!
    $sortBy: SourcingRequestSortField!
    $status: [SourcingRequestStatusEnum]
    $time: DateRangeInput
    $collab: BrandCollabStatusForSouringRequestEnum
    $storeStatus: [StoreStatusEnum]
    $barter: Boolean
    $nextDate: DateRangeInput
    $orderStatus: [SourcingRequestOrderStatusFilterEnum]
    $recommended: Boolean
  ) {
    sourcingRequest(
      first: 10
      filter: {
        search: $email
        stores: $stores
        brands: $brands
        sourcingRequestStatus: $status
        timePeriod: $time
        brandCollab: $collab
        storeStatus: $storeStatus
        storeBarter: $barter
        nextDate: $nextDate
        orderStatus: $orderStatus
        recommended: $recommended
      }
      after: $endCursor
      sortBy: { direction: $direction, field: $sortBy }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          notification
          nextTask
          nextDate
          orderStatus
          orderId
          stopSourceWithZaamo
          instagramLink
          requestReceived
          brandCouponCreated
          brandSharedDeliverables
          daysSinceProductsDelivered
          deliveryDate
          recommended
          store {
            storeName
            storeUrl
            metadata
          }
          storeStatus
          influencerManagers
          product {
            id
            name
            images {
              url
              alt
            }
            thumbnail {
              url
            }
          }
          variant {
            id
            quantityAvailable
            name
            costPrice {
              amount
            }
            price {
              amount
            }
          }
          brand {
            brandName
            brandBarterGuidelines
          }
          brandCollab
          brandManagers
          status
          userLastUpdatedEmail
          content
          updatedAt
          createdAt
        }
      }
    }
  }
`;
